import type { ButtonProps } from "baseui/button";
import { Button } from "baseui/button";
import React from "react";
import { CopyIcon } from "../../icons/CopyIcon";
import { ButtonKind, ButtonSize } from "./Button";

/** A button for copying content */
export const CopyButton = (props: ButtonProps & { mode?: "light" | "dark" }) => {
  return (
    <Button
      kind={ButtonKind.minimal}
      size={ButtonSize.mini}
      startEnhancer={<CopyIcon $style={{ marginRight: "-4px" }} />}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            ...$theme.typography.MonoLabelXSmall,
            color: props.mode === "light" ? $theme.colors.primary700 : $theme.colors.primary300,
            ":hover": {
              backgroundColor: "rgba(255,255,255,0.1)",
            },
          }),
        },
      }}
      {...props}
    >
      Copy
    </Button>
  );
};
