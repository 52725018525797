import { concat, isEqual, pick, uniq, without } from "lodash";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useFlags } from "web/src/lib/flags";
import { currentDocsArea } from "./DocsContext";
import type { DocsArea } from "./DocsInterface";
import type { FullMetaBlob } from "./FullMetaBlob";
import { DocsNavItems } from "./nav/DocsNavContents";
import { GadgetItemProps } from "./nav/navigation-types";
import { useDocsLocation } from "./nav/useDocsLocation";

type FlatNavItem = { itemId: string; path: string[]; depth: number; serializedPath: string };

// TODO: there is probably a more efficient way to do this (especially given that static routes, eg. guides, should not be recalculated during a client's session)
export const flattenNavItemTree = (items: GadgetItemProps[]): readonly FlatNavItem[] => {
  const flatList: FlatNavItem[] = [];
  const collectSubnavs = (item: GadgetItemProps, path: string[] = [item.itemId], depth = 0) => {
    if (!item) {
      return;
    }
    flatList.push({ ...pick(item, ["itemId"]), depth, path, serializedPath: JSON.stringify(path) });
    item.subNav?.forEach((subItem: any) => collectSubnavs(subItem, [...path, subItem.itemId], depth + 1));
  };
  items?.forEach((v) => collectSubnavs(v));
  return Object.freeze(flatList);
};

export const DocsNavContext = createContext<{
  expandNavItem: (itemId: string | string[]) => void;
  collapseNavItem: (itemId: string) => void;
  getIsExpanded: (itemId: string) => boolean;
  setCurrentApp: React.Dispatch<React.SetStateAction<FullMetaBlob>>;
  isNavigating: boolean;
}>(null as any);

const isInArea = (docsArea: DocsArea) => (item: string) => currentDocsArea(item) === docsArea;

export const DocsNavContextProvider = (props: { children: React.ReactNode; isNavigating?: boolean; currentApp: FullMetaBlob }) => {
  const [location] = useDocsLocation();
  const docsArea = currentDocsArea(location);
  const [currentApp, setCurrentApp] = useState<FullMetaBlob>(props.currentApp);
  const isInitialRender = useRef<boolean>(true);
  const flags = useFlags();

  const DocsNavFlat = useMemo(() => flattenNavItemTree(DocsNavItems(location, flags, currentApp)), [location, currentApp, flags]);

  const [expandedItemIds, setExpandedItemIds] = useState<string[]>(() => {
    let storedNavState = null;
    isInitialRender.current = false;
    if (typeof localStorage !== "undefined" && (storedNavState = localStorage.getItem("navigation-docs")) !== null) {
      try {
        return JSON.parse(storedNavState).filter(isInArea(docsArea));
      } catch (error) {
        console.warn("Could not set navigation state from localStorage.", String(error));
      }
    }
    return [];
  });

  const expandNavItem = useCallback(
    (itemId: string | string[]) => {
      // Recursively expand parents of current location
      const newArray = uniq(concat(expandedItemIds, itemId));
      if (!isEqual(newArray, expandedItemIds)) setExpandedItemIds(newArray);
    },
    [expandedItemIds]
  );

  const collapseNavItem = useCallback(
    (itemId: string) => {
      setExpandedItemIds(without(expandedItemIds, itemId));
    },
    [expandedItemIds]
  );

  useEffect(() => {
    // When we navigate to a new location in the docs, we expand it and its parents in the nav
    const currentNavItem = DocsNavFlat.find((item) => item.itemId === location);
    if (currentNavItem) {
      expandNavItem(currentNavItem.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, DocsNavFlat]);

  useEffect(() => {
    // Save any changes to expandedItemIds to LS
    if (typeof localStorage !== "undefined" && isInitialRender?.current === false) {
      localStorage.setItem("navigation-docs", JSON.stringify(expandedItemIds.filter(isInArea(docsArea))));
    }
  }, [expandedItemIds, docsArea]);

  const getIsExpanded = useCallback<(itemId: string) => boolean>((itemId) => expandedItemIds.includes(itemId), [expandedItemIds]);

  return (
    <DocsNavContext.Provider
      value={{
        expandNavItem,
        collapseNavItem,
        getIsExpanded,
        setCurrentApp,
        isNavigating: props.isNavigating ?? false,
      }}
    >
      {props.children}
    </DocsNavContext.Provider>
  );
};

export const useDocsNav = () => {
  return useContext(DocsNavContext);
};
