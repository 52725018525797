import { Row } from "@gadgetinc/widgets";
import { ChevronRightIcon } from "@gadgetinc/widgets/src/icons/ChevronRightIcon";
import { useStyletron } from "baseui";
import { LabelMedium, LabelSmall } from "baseui/typography";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { CenteredSpin } from "web/src/components/chrome/CenteredSpin";
import { DocLink, InlineCode } from "../../markdown-components";
import { DocsContext } from "../DocsContext";
import type { DocsSearchState, SearchResultHit, SearchTabType } from "./DocsSearchState";
import { groupTypeLabel } from "./DocsSearchState";
import { SearchResult } from "./SearchResult";

export const SearchResultList = observer(
  (props: { state: DocsSearchState; onSelect: (result: SearchResultHit) => void; filterResultType?: SearchTabType }) => {
    const [css, $theme] = useStyletron();
    const results = props.filterResultType ? props.state.results.filter((r) => r.type == props.filterResultType) : props.state.results;

    if (results.length == 0) {
      if (props.state.loading) {
        return <CenteredSpin />;
      }
      return <NoSearchResultsFound />;
    }

    return (
      <ul className={css({ display: "flex", flexDirection: "column", gap: $theme.sizing.scale600 })}>
        {results.map((group) => (
          <li key={group.pageURL}>
            <LabelSmall $style={{ color: $theme.colors.contentAccent, marginBottom: $theme.sizing.scale200 }}>
              <Row $gap="2px">
                {groupTypeLabel(group.type)}
                <ChevronRightIcon style={{ width: $theme.sizing.scale500, height: $theme.sizing.scale500 }} />
                <span dangerouslySetInnerHTML={{ __html: group.title }} />
              </Row>
            </LabelSmall>
            <ul>
              {group.results.map((result) => (
                <li key={result.hit.objectID} onClick={() => props.onSelect(result)}>
                  <SearchResult state={props.state} result={result} />
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    );
  }
);

const NoSearchResultsFound = () => {
  const [css] = useStyletron();
  const { currentApp, currentUser } = useContext(DocsContext);

  return (
    <div
      className={css({
        textAlign: "center",
      })}
    >
      <LabelMedium>No results found</LabelMedium>
      <br />
      {currentApp && currentUser ? (
        <>
          <LabelSmall>
            Currently searching the docs for <InlineCode>{currentApp.slug}</InlineCode>
          </LabelSmall>
        </>
      ) : (
        <>
          <LabelSmall>
            You&apos;re not currently logged in. Try <DocLink href="https://app.gadget.dev/auth/login">logging in</DocLink> for more
            results.
          </LabelSmall>
        </>
      )}
    </div>
  );
};
