import { Input, InputSize, expandBorderRadii, expandPadding, paddingHorizontal, paddingVertical } from "@gadgetinc/widgets";
import { SearchIcon } from "@gadgetinc/widgets/src/icons/SearchIcon";
import { SearchSlashIcon } from "@gadgetinc/widgets/src/icons/SearchSlashIcon";
import { useStyletron } from "baseui";
import { Modal } from "baseui/modal";
import { Tab, Tabs } from "baseui/tabs-motion";
import type { Theme } from "baseui/theme";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import { GlobalHotKeys, HotKeys } from "react-hotkeys-ce";
import { useRouter } from "wouter";
import { trackSearchResultClicked } from "../../DocsAlgoliaSearchIndex";
import { useDocsContext } from "../DocsContext";
import type { FullMetaBlob } from "../FullMetaBlob";
import { useDocsLocation } from "../nav/useDocsLocation";
import type { SearchResultHit, SearchTabType } from "./DocsSearchState";
import { DocsSearchState } from "./DocsSearchState";
import { SearchResultList } from "./SearchResultList";
import { currentDocsVersion } from "../nav/DocsVersionSelector";

export const DocsSearchBar = observer((props: { currentApp: FullMetaBlob; modalMountNode?: HTMLElement | null }) => {
  const [css, $theme] = useStyletron();
  const { currentUser, setShowTsLanguage } = useDocsContext();
  const [_location, setLocation] = useDocsLocation();
  const router = useRouter();

  const highlightClassName = css({ fontWeight: 500, backgroundColor: "none", fontStyle: "normal" });

  const searchOptions = {
    highlightPreTag: `<em class="${highlightClassName}">`,
    highlightPostTag: "</em>",
  };

  const currentAppEnvironment = useMemo(() => {
    return {
      applicationID: props.currentApp.id,
      environmentID: props.currentApp.environmentID,
      isExample: props.currentApp.isExample,
    };
  }, [props.currentApp]);

  const state = useMemo(
    () => new DocsSearchState(currentAppEnvironment, currentUser ?? null, searchOptions),
    [currentAppEnvironment, highlightClassName]
  );

  const navigate = useCallback(
    (result: SearchResultHit) => {
      state.setOpen(false);
      trackSearchResultClicked(currentAppEnvironment, state.queryID!, result.hit.objectID, result.position);
      const url = new URL(result.hit.url);
      if (url.hostname == window.location.hostname && url.pathname.startsWith(router.base)) {
        setLocation(url.pathname.replace(router.base, "") + url.hash);
      } else {
        window.location.href = url.toString();
      }
    },
    [props.currentApp, router.base, setLocation, state]
  );

  const startSearch = useCallback(
    (event) => {
      event.preventDefault();
      event.target.blur();
      state.reset();
      state.setOpen(true);
    },
    [state]
  );

  useEffect(() => {
    // baseui's modal component sometimes doesn't remove the `overflow: "hidden";` style it adds to the body when the component is unmounted at the same time as the modal is closed
    // work around this baseui bug by ensuring the overflow: hidden property is removed from the body as the search box unmounts
    if (typeof window != "undefined" && !state.open) {
      window.document.body.style.removeProperty("overflow");
    }
  }, [state.open]);

  const keyMap = {
    NAVIGATE: () => navigate(state.activeResult),
    MOVE_UP: () => state.deltaActivePosition(-1),
    MOVE_DOWN: () => state.deltaActivePosition(1),
    CLOSE: () => state.setOpen(false),
  };

  const tabOverrides = {
    TabPanel: {
      style: {
        backgroundColor: "transparent",
        paddingBottom: $theme.sizing.scale600,
        paddingLeft: $theme.sizing.scale600,
        paddingRight: $theme.sizing.scale600,
      },
    },
    Tab: {
      style: ({ $theme, $isActive }: { $theme: Theme; $isActive?: boolean }) => ({
        ...paddingVertical($theme.sizing.scale100),
        ...paddingHorizontal($theme.sizing.scale600),
        fontWeight: $isActive ? 500 : undefined,
        color: $isActive ? $theme.colors.contentPrimary : $theme.colors.contentSecondary,
        backgroundColor: $isActive ? $theme.colors.primary100 : $theme.colors.primary50,
        ...expandBorderRadii($theme.borders.radius200),
        ":hover": {
          background: $theme.colors.primary100,
        },
      }),
    },
  };

  return (
    <>
      <Input
        placeholder="Find anything"
        onFocus={startSearch}
        startEnhancer={<SearchIcon style={{ color: $theme.colors.primary400 }} />}
        endEnhancer={
          <SearchSlashIcon
            pathFillColor={$theme.colors.alpha500}
            style={{
              backgroundColor: $theme.colors.alpha100,
              borderWidth: 0,
              boxShadow: "0px -1px 0px 0px #00000024 inset",
              height: $theme.sizing.scale700,
              width: $theme.sizing.scale700,
            }}
          />
        }
        overrides={{
          Root: {
            style: { height: $theme.sizing.scale900 /*32px*/, borderColor: $theme.colors.border, minWidth: "165px", width: "200px" },
          },
        }}
      ></Input>

      <HotKeys
        handlers={keyMap}
        keyMap={{
          NAVIGATE: ["enter"],
          MOVE_UP: ["up"],
          MOVE_DOWN: ["down"],
          CLOSE: ["escape"],
        }}
      >
        <Modal
          onClose={() => state.setOpen(false)}
          closeable
          isOpen={state.open}
          animate={false}
          autoFocus
          unstable_ModalBackdropScroll
          mountNode={props.modalMountNode ? props.modalMountNode : undefined}
          overrides={{
            Dialog: {
              style: {
                width: "max(550px, 40%)",
                maxHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                backgroundColor: $theme.colors.primary50,
                ...expandBorderRadii($theme.borders.radius200),
              },
            },
            DialogContainer: {
              style: {
                paddingTop: "10vh",
                alignItems: "start",
              },
            },
            Close: {
              component: () => null,
            },
          }}
        >
          <div
            className={css({
              ...expandPadding($theme.sizing.scale600),
            })}
          >
            <Input
              placeholder="Find anything"
              value={state.search}
              onChange={(event: React.SyntheticEvent) => {
                const value = (event.target as HTMLInputElement).value;
                state.setSearch(value);
                void state.debouncedRun();
              }}
              size={InputSize.compact}
              startEnhancer={<SearchIcon />}
              onKeyDown={(event) => {
                if (event.key == "ArrowUp") {
                  keyMap.MOVE_UP();
                } else if (event.key == "ArrowDown") {
                  keyMap.MOVE_DOWN();
                } else if (event.key == "Enter") {
                  keyMap.NAVIGATE();
                } else if (event.key == "Escape") {
                  keyMap.CLOSE();
                }
              }}
              overrides={{
                StartEnhancer: {
                  style: {
                    paddingLeft: $theme.sizing.scale500,
                    paddingRight: "0px",
                    color: $theme.colors.primary500,
                  },
                },
                Input: {
                  style: {
                    color: $theme.colors.primary500,
                    paddingLeft: $theme.sizing.scale400,
                    boxShadow: $theme.lighting.shadow400,
                  },
                },
              }}
            />
          </div>
          {state.started && state.search.length > 0 && (
            <div className={css({ overflowY: "auto" })}>
              <Tabs
                overrides={{
                  TabList: {
                    style: () => ({
                      gap: "10px",
                      paddingLeft: $theme.sizing.scale600,
                      paddingRight: $theme.sizing.scale600,
                    }),
                  },
                  TabBorder: {
                    style: () => ({
                      height: "1px",
                      marginTop: $theme.sizing.scale500,
                    }),
                  },
                  TabHighlight: {
                    component: () => null,
                  },
                }}
                activeKey={state.currentTab}
                onChange={({ activeKey }) => {
                  state.setCurrentTab(activeKey.toString() as SearchTabType);
                }}
              >
                <Tab key="all" title="All Results" overrides={tabOverrides}>
                  <SearchResultList state={state} onSelect={navigate} />
                </Tab>
                <Tab key="guides" title="Guides" overrides={tabOverrides}>
                  <SearchResultList state={state} onSelect={navigate} filterResultType="guides" />
                </Tab>
                <Tab key="api" title="API" overrides={tabOverrides}>
                  <SearchResultList state={state} onSelect={navigate} filterResultType="api" />
                </Tab>
                <Tab key="reference" title="Reference" overrides={tabOverrides}>
                  <SearchResultList state={state} onSelect={navigate} filterResultType="reference" />
                </Tab>
              </Tabs>
            </div>
          )}
        </Modal>
      </HotKeys>
      <GlobalHotKeys
        handlers={{
          START_SEARCH: startSearch,
          TOGGLE_LANGUAGE: () => setShowTsLanguage((prev) => !prev),
        }}
        keyMap={{
          START_SEARCH: ["/"],
          TOGGLE_LANGUAGE: ["ctrl+l"],
        }}
      />
    </>
  );
});
