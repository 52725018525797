import React from "react";
import { parameterize } from "superflected";
import { getChildrenText } from "web/src/lib/utils";

export const crawlingHintRegex = /\[~(.*?)~\]/;

export interface ExtractedHeading {
  url: string;
  depth: number;
  text: string;
}

const tagsByLevel = [
  ["h2", "h3", "h4", "h5"],
  ["h2", "h3", "h4"],
  ["h2", "h3"],
];

// taken from https://gist.github.com/jaredpalmer/b437cefc503f876faabb34eb04ad176f and modified
export const extractMDXHeadings = (children: React.ReactNode, maxDepth = 5, level = 0) => {
  let headings: ExtractedHeading[] = [];

  React.Children.toArray(children).forEach((child: any) => {
    const { props } = child;
    const { children, id } = props ?? {};
    const mdxType = props?.mdxType ? props.mdxType.toLowerCase() : undefined;

    if (level < 2 && !mdxType && children && Array.isArray(children)) {
      headings = headings.concat(extractMDXHeadings(children, maxDepth, level + 1));
    } else if (mdxType == "fragment") {
      headings = headings.concat(extractMDXHeadings(children, maxDepth, level));
    } else if (mdxType && tagsByLevel[level].includes(mdxType)) {
      const text = getChildrenText(children)?.replace(crawlingHintRegex, "");
      const slug = id ?? parameterize(text);

      const depth = (mdxType && parseInt(mdxType.replace("h", ""), 0)) ?? 0;
      if (depth <= maxDepth) {
        headings.push({
          url: `#${slug}`,
          depth,
          text,
        });
      }
    }
  });

  return headings;
};
