import { DateTime } from "luxon";

/** Get the deprecation date for a particular Shopify API Version. Each is supported for 1 year and stable versions are released on the 1st of the month at 5pm UTC */
export const apiDeprecationDate = (apiVersion: ShopifyApiVersion) => {
  const [year, month] = apiVersion.split("-");

  return DateTime.fromObject(
    {
      year: parseInt(year),
      month: parseInt(month),
      day: 1,
      hour: 17,
    },
    { zone: "UTC" }
  ).plus({ year: 1 });
};

export const isShopifyApiVersionEarlierOrEqualTo = (apiVersion: ShopifyApiVersion, earlierThanVersion: ShopifyApiVersion) => {
  const version = DateTime.fromFormat(apiVersion, "yyyy-MM");
  const versionToCheck = DateTime.fromFormat(earlierThanVersion, "yyyy-MM");

  return version <= versionToCheck;
};

/** Which version of the Shopify API is Gadget currently using for the code Gadget owns to talk to Shopify */
export const INTERNAL_SHOPIFY_API_VERSION: ShopifyApiVersion = "2025-04";

export const PRERELEASE_SHOPIFY_API_VERSION: ShopifyApiVersion | undefined = undefined;

/** All currently available versions of the Shopify API */
export const SHOPIFY_API_VERSIONS = [
  "2022-01",
  "2022-04",
  "2022-07",
  "2022-10",

  "2023-01",
  "2023-04",
  "2023-07",
  "2023-10",

  "2024-01",
  "2024-04",
  "2024-07",
  "2024-10",

  "2025-01",
  "2025-04",
] as const;

/** All currently available versions of the Shopify API that Gadget publicly supports */
export const GADGET_PUBLICLY_SUPPORTED_API_VERSIONS = SHOPIFY_API_VERSIONS.filter((version) => version !== PRERELEASE_SHOPIFY_API_VERSION);

export const LATEST_API_VERSION: ShopifyApiVersion = SHOPIFY_API_VERSIONS.filter(
  (version) => version !== PRERELEASE_SHOPIFY_API_VERSION
).slice(-1)[0];

export const SHOP_GRAPHQL_SYNC_VERSION = "2025-04";

export type ShopifyApiVersion = (typeof SHOPIFY_API_VERSIONS)[number];
