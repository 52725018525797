import React, { useContext } from "react";
import { useFlags } from "web/src/lib/flags";
import { useLocation } from "wouter";
import { shouldShowEnvironmentSelector, shouldShowVersionSelector } from "../utils/shouldShowVersionSelector";
import { currentDocsArea, DocsContext } from "./DocsContext";
import { DocsAPIEnvironmentSelector } from "./nav/DocsAPIEnvironmentSelector";
import { DocsLanguageSelector } from "./nav/DocsLanguageSelector";
import { DocsFrameworkVersionSelector } from "./nav/DocsVersionSelector";

export const DocsDropDownSelectors = (props: {
  useFullFrameworkLabel?: boolean;
  addBoxShadow?: boolean;
  forceShowVersionSelector?: boolean;
  forceShowEnvironmentSelector?: boolean;
}) => {
  const { useFullFrameworkLabel, addBoxShadow, forceShowVersionSelector, forceShowEnvironmentSelector } = props;

  const [location] = useLocation();
  const currentArea = currentDocsArea(location);
  const flags = useFlags();
  const { currentApp, availableApps, currentUser } = useContext(DocsContext);

  const showVersionSelector =
    forceShowVersionSelector ?? shouldShowVersionSelector(currentArea, availableApps, flags, currentApp, currentUser);
  const showEnvironmentSelector =
    forceShowEnvironmentSelector ?? shouldShowEnvironmentSelector(currentArea, availableApps, flags, currentApp, currentUser);

  return (
    <>
      {showVersionSelector && (
        <BoxShadower enabled={addBoxShadow}>
          <DocsFrameworkVersionSelector useFullFrameworkLabel={useFullFrameworkLabel} />
        </BoxShadower>
      )}
      {showEnvironmentSelector && (
        <BoxShadower enabled={addBoxShadow}>
          <DocsAPIEnvironmentSelector />
        </BoxShadower>
      )}
      <BoxShadower enabled={addBoxShadow}>
        <DocsLanguageSelector />
      </BoxShadower>
    </>
  );
};

/**
 * Need this extra shadower to help separate the dropdown buttons from the text that they overlay
 */
const BoxShadower = (props: { children: React.ReactNode; enabled?: boolean }) => {
  if (!props.enabled) {
    return <>{props.children}</>;
  }
  return (
    <div
      style={{
        boxShadow: "0px -1px 0px 0px rgba(0, 0, 0, 0.24) inset, 0px 4px 12px 0px rgba(0, 0, 0, 0.10)",
        borderRadius: 6,
      }}
    >
      {props.children}
    </div>
  );
};
