import { flatten } from "lodash";

export enum WorkspacePageType {
  Home = "Home",
  GlobalActions = "GlobalActions",
  DataEdit = "DataEdit",
  Code = "Code",
  CodeHome = "CodeHome",
  ApiKeys = "ApiKeys",
  Logs = "Logs",
  PluginsIndex = "PluginsIndex",
  ShopifyConnectionsIndex = "ShopifyConnectionsIndex",
  ShopifyConnectionsAPIUpgrade = "ShopifyConnectionsAPIUpgrade",
  ShopifyInstalls = "ShopifyInstalls",
  ConnectionInstalls = "ConnectionInstalls",
  ShopifyShopSyncHistory = "ShopifyShopSyncHistory",
  EditShopifyAppCredentials = "EditShopifyAppCredentials",
  AddShopifyAppCredentials = "AddShopifyAppCredentials",
  PermissionsIndex = "PermissionsIndex",
  ConfigurationVariablesIndex = "ConfigurationVariablesIndex",
  ServerOperationLog = "ServerOperationLog",
  BackgroundActionsIndex = "BackgroundActionsIndex",
  BackgroundActionHistory = "BackgroundActionHistory",
  RoutePreview = "RoutePreview",
  Playground = "Playground",
  Terminal = "Terminal",
  Error = "Error",
  Test = "Test",
  Typecheck = "Typecheck",
  Model = "Model",
  AppSettingIndex = "AppSettingIndex",
  GoogleOauthSettingsIndex = "GoogleOauthSettingsIndex",
  AuthSettingsIndex = "AuthSettingsIndex",
  FrameworkVersionChange = "FrameworkVersionChange",
  OpenAIConnectionIndex = "OpenAIConnectionIndex",
  SentryConnectionIndex = "SentryConnectionIndex",
  BigCommerceConnectionIndex = "BigCommerceConnectionIndex",
  EditBigCommerceAppCredentials = "EditBigCommerceAppCredentials",
  Docs = "Docs",
}

export const SettingsPageTypes = [
  WorkspacePageType.PluginsIndex,
  WorkspacePageType.ShopifyConnectionsIndex,
  WorkspacePageType.ShopifyConnectionsAPIUpgrade,
  WorkspacePageType.ShopifyInstalls,
  WorkspacePageType.EditShopifyAppCredentials,
  WorkspacePageType.AddShopifyAppCredentials,
  WorkspacePageType.ShopifyConnectionsAPIUpgrade,
  WorkspacePageType.ConfigurationVariablesIndex,
  WorkspacePageType.AppSettingIndex,
  WorkspacePageType.GoogleOauthSettingsIndex,
  WorkspacePageType.AuthSettingsIndex,
  WorkspacePageType.FrameworkVersionChange,
  WorkspacePageType.OpenAIConnectionIndex,
  WorkspacePageType.SentryConnectionIndex,
  WorkspacePageType.BigCommerceConnectionIndex,
  WorkspacePageType.EditBigCommerceAppCredentials,
];

export const SelectableEnvironmentPageTypes = [WorkspacePageType.Logs, WorkspacePageType.DataEdit];

export const DataModelConfigPageTypes = [WorkspacePageType.Model, WorkspacePageType.DataEdit];

export const pageTypesByConnectionIndexPageType: { [key in WorkspacePageType]?: WorkspacePageType[] } = {
  [WorkspacePageType.ShopifyConnectionsIndex]: [
    WorkspacePageType.ShopifyInstalls,
    WorkspacePageType.ShopifyShopSyncHistory,
    WorkspacePageType.ShopifyConnectionsAPIUpgrade,
  ],
};

export const ConnectionPageTypes = [WorkspacePageType.PluginsIndex]
  .concat(Object.keys(pageTypesByConnectionIndexPageType) as WorkspacePageType[])
  .concat(flatten(Object.values(pageTypesByConnectionIndexPageType)));

export const WorkspacePageTypeTags = {
  code: "code_file",
  model: "data_model",
  home: "home_page",
  logs: "logs_viewer",
  typecheck: "typecheck",
  docs: "api_docs",
  playground: "api_playground",
  preview: "app_preview",
  settings: "settings",
  connectionInstalls: "connection_installs_list",
  shopifyShopSyncHistory: "shopify_shop_sync_history",
  backgroundActions: "background_actions_list",
  error: "editor_error",
  terminal: "editor_terminal",
};
