import { Row, expandBorderRadii } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { StatefulMenu } from "baseui/menu";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { compact } from "lodash";
import React, { useContext } from "react";
import Gravatar from "react-gravatar";
import { DocsButton } from "../../components/DocsButton";
import { DocsContext } from "../DocsContext";

export const DocsUserMenu = () => {
  const [css, $theme] = useStyletron();
  const { currentUser } = useContext(DocsContext);
  if (typeof window !== "undefined" && window.self !== window.top) {
    // hide when iframed into the editor
    return null;
  }

  if (currentUser) {
    return (
      <StatefulPopover
        placement={PLACEMENT.bottomRight}
        popoverMargin={0}
        content={({ close }) => (
          <StatefulMenu
            items={compact([
              {
                label: <Row $gap={$theme.sizing.scale400}>App List</Row>,
                onSelect: () => (window.location.href = `${window.GadgetDocsConfig.servicesRootURL}/auth/team`),
              },
              {
                label: <Row $gap={$theme.sizing.scale400}>Logout</Row>,
                onSelect: () => (window.location.href = `${window.GadgetDocsConfig.servicesRootURL}/auth/logout`),
              },
            ])}
            onItemSelect={({ item }) => {
              item.onSelect();
            }}
          />
        )}
      >
        <Row style={{ flexShrink: 0 }}>
          <Gravatar
            email={currentUser.email ?? "anonymous@gadget.dev"}
            size={32}
            rating="pg"
            default={process.env.NODE_ENV === "test" ? "mp" : "monsterid"}
            className={css({
              ...expandBorderRadii("50%"),
              cursor: "pointer",
            })}
          />
        </Row>
      </StatefulPopover>
    );
  } else {
    return (
      <DocsButton
        overrides={{
          BaseButton: {
            style: {
              minWidth: "100px",
              borderRadius: "8px",
              padding: "7px 12px",
              fontSize: "14px",
            },
          },
        }}
        $as="a"
        kind={KIND.primary}
        href={`https://app.gadget.dev/auth/signup`}
      >
        Create app
      </DocsButton>
    );
  }
};
