import { paddingHorizontal } from "@gadgetinc/widgets";
import { SidebarNavBreakpoint, WideSidebarNavBreakpoint } from "@gadgetinc/widgets/src/DocsTheme";
import { APIPlaygroundIcon } from "@gadgetinc/widgets/src/icons/APIPlaygroundIcon";
import { useStyletron } from "baseui";
import { useNavigationDetails } from "fastify-renderer/client/react";
import type { ForwardedRef } from "react";
import React, { forwardRef, useContext, useEffect, useMemo, useRef } from "react";
import { useRouter } from "wouter";
import { Divider } from "../Divider";
import { DocsContext, currentDocsArea } from "../DocsContext";
import { DocsNavLink } from "./DocsNavLink";
import { currentDocsVersion } from "./DocsVersionSelector";
import { StatefulNavSidebar } from "./StatefulNavSidebar";
import { navItemIsActive, type GadgetItemProps } from "./navigation-types";
import { useDocsLocation } from "./useDocsLocation";

export const SmallSidebarWidth = "265px";
export const LargeSidebarWidth = "280px";

export const DocsLeftNav = forwardRef((_props, _ref?: ForwardedRef<HTMLDivElement>) => {
  const [css, $theme] = useStyletron();
  const [location] = useDocsLocation();
  const [_, navigationLocation] = useNavigationDetails();
  const currentArea = currentDocsArea(location);
  const router = useRouter();
  const { currentApp, topLevelItems } = useContext(DocsContext);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const currentVersion = currentDocsVersion(location);

  const secondLevelItems = useMemo<GadgetItemProps[]>(() => {
    const section = topLevelItems.find((item) => navItemIsActive(item, navigationLocation ?? location));
    if (section) {
      return section.subNav ?? [];
    } else {
      return topLevelItems.find((item) => item.itemId == currentVersion.rootURL)?.subNav ?? [];
    }
  }, [topLevelItems, navigationLocation, location, currentVersion.rootURL]);

  const defaultNavStyles = css({
    display: "none",
    minWidth: 0,
    backgroundColor: $theme.colors.primary50,
    borderRightWidth: $theme.borders.border100.borderWidth,
    borderRightStyle: $theme.borders.border100.borderStyle as any,
    borderRightColor: $theme.colors.primary200,
    paddingTop: $theme.sizing.scale400,
    [`@media screen and ${SidebarNavBreakpoint}`]: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      height: typeof window !== "undefined" && window.self !== window.top ? "100%" : "calc(100% - 47px)",
      scrollbarWidth: "none",
      "::-webkit-scrollbar": {
        display: "none",
      },
      overflowY: "auto",
      overflowX: "hidden",
      width: SmallSidebarWidth,
    },
    [`@media screen and ${WideSidebarNavBreakpoint}`]: {
      width: LargeSidebarWidth,
    },
  });

  const saveNavScrolltopPosition = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (containerRef?.current) {
      localStorage.setItem("position", `${containerRef.current.scrollTop}`);
    }
  };

  // TODO: Improve this so that it accounts for the layout shift that happens with the accordion
  useEffect(() => {
    const scrollToPosition = localStorage.getItem("position");
    if (scrollToPosition && containerRef?.current && !router.navigationHistory?.current?.replace) {
      containerRef.current.scrollTo(0, +scrollToPosition);
      localStorage.setItem("position", "0");
    }
  }, [location]);

  return (
    <div data-testid="docs-left-nav" ref={containerRef} className={`docs-left-nav ${defaultNavStyles}`} onClick={saveNavScrolltopPosition}>
      <div
        className={css({
          backgroundColor: $theme.colors.primary50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "calc(100% - 16px)",
        })}
      >
        <div data-testid="docsStatefulLeftNav" className={css({ ...paddingHorizontal($theme.sizing.scale300) })}>
          <StatefulNavSidebar subNav={secondLevelItems} depth={1} />
          {currentArea == "guides" && (
            <>
              <div className={css({ marginTop: $theme.sizing.scale700, marginBottom: $theme.sizing.scale500 })}>
                <Divider />
              </div>
              <DocsNavLink anchorProps={{ href: "/guides/gadget-framework" }}> Gadget framework </DocsNavLink>
              <DocsNavLink anchorProps={{ href: "/guides/glossary" }}> Glossary </DocsNavLink>
              <DocsNavLink anchorProps={{ href: "/guides/faq" }}> FAQ </DocsNavLink>
            </>
          )}
        </div>
        <div
          className={css({
            marginTop: $theme.sizing.scale900,
            ...paddingHorizontal($theme.sizing.scale300),
            paddingBottom: $theme.sizing.scale500,
          })}
        >
          {currentArea == "api" && (
            <DocsNavLink
              anchorProps={{
                href: `${currentApp.canonicalRenderURL}api/playground/javascript?environment=${currentApp.environment.slug}`,
                target: "_blank",
              }}
              icon={<APIPlaygroundIcon size={16} />}
            >
              API playground
            </DocsNavLink>
          )}
        </div>
      </div>
    </div>
  );
});
