import { GadgetOnlyHeader, NavItem, PageLevelHeading } from "@gadgetinc/widgets/src/types/navigation-types";
import { Divider } from "../shared/Divider";
import React from "react";

type TemplateNavItem = NavItem & PageLevelHeading;

export const getApiReferencesDocsNavItems = (props: {
  apiReferenceBase: string;
  templateArchitectureNav?: TemplateNavItem[];
  templateSetupNav?: TemplateNavItem[];
  modelNamespacedApiIdentifiers: string[];
  hasGlobalActions?: boolean;
}) => {
  const {
    constantApiReferencesDocsNav,
    schemaApiDocsNavItems: { models, globalActions },
  } = getGroupedApiReferencesDocsNavItems(props);
  return [...constantApiReferencesDocsNav, ...models, ...(globalActions ?? [])];
};

export const getGroupedApiReferencesDocsNavItems = (props: {
  apiReferenceBase: string;
  templateArchitectureNav?: TemplateNavItem[];
  templateSetupNav?: TemplateNavItem[];
  modelNamespacedApiIdentifiers: string[];
  hasGlobalActions?: boolean;
}) => {
  return {
    constantApiReferencesDocsNav: getConstantApiReferencesDocsNavItems(props),
    schemaApiDocsNavItems: getSchemaApiDocsNavItems(props),
  };
};

const getConstantApiReferencesDocsNavItems = (props: {
  apiReferenceBase: string;
  templateArchitectureNav?: TemplateNavItem[];
  templateSetupNav?: TemplateNavItem[];
}) => {
  const { apiReferenceBase, templateArchitectureNav, templateSetupNav } = props;
  return [
    {
      title: `API Overview`,
      itemId: apiReferenceBase,
      nodeName: "H1" as const,
    },

    ...(templateArchitectureNav ?? []),
    ...(templateSetupNav ?? []),

    {
      title: `API calls within Gadget`,
      itemId: `${apiReferenceBase}/api-calls-within-gadget`,
      nodeName: "H1" as const,
    },
    {
      title: "External API calls with Gadget",
      itemId: `${apiReferenceBase}/external-api-calls`,
      nodeName: "H1" as const,
      subNav: [
        {
          nodeName: "H1" as const,
          title: "Installing",
          itemId: `${apiReferenceBase}/external-api-calls/installing`,
        },
        {
          nodeName: "H1" as const,
          title: "Authentication",
          itemId: `${apiReferenceBase}/external-api-calls/authentication`,
        },
        {
          nodeName: "H1" as const,
          title: "GraphQL",
          itemId: `${apiReferenceBase}/external-api-calls/graphql`,
        },
      ],
    },
    {
      nodeName: "H1" as const,
      title: "Record",
      itemId: `${apiReferenceBase}/gadget-record`,
    },
    {
      nodeName: "H1" as const,
      title: "Internal vs Public API",
      itemId: `${apiReferenceBase}/internal-api`,
    },
    {
      nodeName: "H1" as const,
      title: "Sorting and filtering",
      itemId: `${apiReferenceBase}/sorting-and-filtering`,
    },
    {
      nodeName: "H1" as const,
      title: "Errors",
      itemId: `${apiReferenceBase}/errors`,
    },
    {
      title: <Divider />,
      itemId: `${apiReferenceBase}/api-schema/divider`,
      disabled: true,
      onlyHeader: GadgetOnlyHeader.Header,
    },
  ];
};

const getSchemaApiDocsNavItems = (props: {
  apiReferenceBase: string;
  modelNamespacedApiIdentifiers: string[];
  hasGlobalActions?: boolean;
}) => {
  const { apiReferenceBase, modelNamespacedApiIdentifiers, hasGlobalActions } = props;

  const models = [
    {
      title: "Schema",
      itemId: `${apiReferenceBase}/schema`,
      disabled: true,
      onlyHeader: GadgetOnlyHeader.BoldedHeader,
    },
    ...modelNamespacedApiIdentifiers
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map((modelNamespacedApiIdentifier) => {
        return {
          title: modelNamespacedApiIdentifier,
          itemId: `${apiReferenceBase}/schema/${modelNamespacedApiIdentifier}`,
          nodeName: "H1" as const as PageLevelHeading["nodeName"],
        };
      }),
  ];

  if (hasGlobalActions) {
    return {
      models,
      globalActions: [
        {
          title: <Divider />,
          itemId: `${apiReferenceBase}/global-actions/divider`,
          disabled: true,
          onlyHeader: GadgetOnlyHeader.Header,
        },
        {
          title: "Global actions",
          itemId: `${apiReferenceBase}/schema/global-actions`,
          nodeName: "H1" as const,
        },
      ],
    };
  }

  return { models };
};
