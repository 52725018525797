import type { Hit, SearchOptions } from "@algolia/client-search";
import { clamp, compact, debounce, groupBy } from "lodash";
// eslint-disable-next-line no-restricted-imports -- this state is using original mobx, not our @gadgetinc/mobx-quick-tree
import { action, flow, makeObservable, observable } from "mobx";
import type { CurrentAppEnvironment, DocsSearchDocument } from "../../DocsAlgoliaSearchIndex";
import { index, trackSearchResults } from "../../DocsAlgoliaSearchIndex";
import type { DocsUser } from "../DocsContext";
import type { DocsVersion, FullMetaBlob } from "../FullMetaBlob";
import { currentDocsVersion } from "../nav/DocsVersionSelector";

export function searchResultHitHighlights(hit: Hit<DocsSearchDocument>) {
  return {
    ...hit._highlightResult,
    ...hit._snippetResult,
  };
}

export function searchResultHitCategories(hit: Hit<DocsSearchDocument>) {
  const highlights = searchResultHitHighlights(hit);

  return compact([highlights.category2, highlights.category3, highlights.category4, highlights.category5, highlights.category6]).map(
    (highlight) => highlight.value
  );
}

export function searchResultHitTitle(hit: Hit<DocsSearchDocument>) {
  const categories = searchResultHitCategories(hit);
  const highlights = searchResultHitHighlights(hit);

  return categories.pop() ?? highlights.category1?.value;
}

export type SearchResultHit = {
  hit: Hit<DocsSearchDocument>;
  position: number;
};

export type SearchResultGroup = {
  title: string;
  type: SearchTabType;
  pageURL: string;
  results: SearchResultHit[];
};

export type SearchTabType = "all" | "guides" | "api" | "reference";

export const groupTypeLabel = (type: SearchTabType) => {
  switch (type) {
    case "guides":
      return "Guides";
    case "api":
      return "API";
    case "reference":
      return "Reference";
    default:
      return type;
  }
};

const LoggedOutEnvironmentID = 9028; // https://example-app.gadget.app/edit

export interface CurrentUser {
  id: string;
}

/** React state for the docs search modal */
export class DocsSearchState {
  loading = false;
  open = false;
  started = false;
  search = "";
  error: Error | null = null;
  queryID?: string;
  results: SearchResultGroup[] = [];
  currentTab: SearchTabType = "all";
  activePosition = 0;

  constructor(
    readonly currentApp: CurrentAppEnvironment,
    readonly currentUser: CurrentUser | null,
    readonly searchOptions: SearchOptions = {}
  ) {
    makeObservable(this, {
      search: observable,
      results: observable,
      error: observable,
      loading: observable,
      open: observable,
      started: observable,
      currentTab: observable,
      activePosition: observable,
      setSearch: action,
      setOpen: action,
      setActivePosition: action,
      reset: action,
    });
  }

  anyResultsOfType(type: SearchTabType) {
    return this.results.some((group) => group.type === type);
  }

  setSearch(search: string) {
    this.search = search;
  }

  setOpen(open: boolean) {
    this.open = open;
  }

  setCurrentTab(tab: SearchTabType) {
    this.currentTab = tab;
  }

  setActivePosition(position: number) {
    this.activePosition = clamp(
      position,
      1,
      this.results.map((group) => group.results.length).reduce((a, b) => a + b, 0)
    );
  }

  get activeResult() {
    return this.results.flatMap((group) => group.results)[this.activePosition - 1];
  }

  // algolia filter string for the current app and docs version
  get filters() {
    // if we have a specific app in context, search for docs matching that app specifically, or docs relevant to any app
    const appChunk = this.currentApp
      ? `(environmentSpecific:false OR environmentId:${this.currentApp.environmentID})`
      : `environmentSpecific:false OR environmentId:${LoggedOutEnvironmentID}`;

    // filter to docs that aren't specific to any docs version, or match the version the user is currently viewing
    const currentVersion = this.currentApp.docsVersion ?? currentDocsVersion(window.location.pathname);
    const versionChunk = `(docsVersionSpecific:false OR docsVersion:${currentVersion.id})`;
    return `${appChunk} AND ${versionChunk}`;
  }

  deltaActivePosition(delta: number) {
    this.setActivePosition(this.activePosition + delta);
  }

  reset() {
    this.search = "";
    this.started = false;
    this.results = [];
  }

  debouncedRun = debounce(() => {
    return this.run();
  });

  run = flow(function* (this: DocsSearchState) {
    this.started = true;
    if (!this.search) {
      this.results = [];
      return;
    }

    this.loading = true;
    const currentVersion = this.currentApp.docsVersion ?? currentDocsVersion(window.location.pathname);

    try {
      const response = yield index.search<DocsSearchDocument>(this.search, {
        filters: this.filters,
        attributesToHighlight: ["category1", "category2", "category3", "category4", "category5", "category6"],
        attributesToRetrieve: ["url"],
        attributesToSnippet: ["content:30"],
        snippetEllipsisText: "…",
        clickAnalytics: true,
        analyticsTags: compact([
          `version-${currentVersion.id}`,
          this.currentApp && `env-${this.currentApp?.environmentID}`,
          this.currentUser ? "logged-in" : "logged-out",
          this.currentUser && `user-${this.currentUser.id}`,
        ]),
        ...this.searchOptions,
      });

      this.results = this.groupHits(response.hits);
      this.setActivePosition(this.activePosition); // fix active position if it's out of bounds

      this.queryID = response.queryID;
      trackSearchResults(this.currentApp, this.search, response);
    } catch (error: any) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  });

  private groupHits(hits: Hit<DocsSearchDocument>[]): SearchResultGroup[] {
    const groupedHits = groupBy(hits, (hit) => hit.url.split("#", 2)[0]);

    let positionCounter = 0;
    return Object.entries(groupedHits).map(([pageURL, group]) => {
      const pageTitle = group[0]._highlightResult!.category1!.value;

      return {
        title: pageTitle,
        pageURL,
        type: pageURL.includes("/api/") ? "api" : pageURL.includes("/reference") ? "reference" : "guides",
        results: group.slice(0, 3).map((hit) => {
          const position = ++positionCounter;
          return {
            hit,
            position,
          };
        }),
      };
    });
  }
}
