import { StyledSvg } from "baseui/icon";
import React from "react";

export const TemplatesIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.5013 2C2.49471 2 1.66797 2.82674 1.66797 3.83333V4.83333C1.66798 4.96594 1.72066 5.09311 1.81443 5.18687C1.9082 5.28064 2.03536 5.33332 2.16797 5.33333H13.8346C13.9672 5.33332 14.0944 5.28064 14.1882 5.18687C14.2819 5.09311 14.3346 4.96594 14.3346 4.83333V3.83333C14.3346 2.82674 13.5079 2 12.5013 2H3.5013ZM3.5013 3H12.5013C12.9674 3 13.3346 3.36726 13.3346 3.83333V4.33333H2.66797V3.83333C2.66797 3.36726 3.03523 3 3.5013 3ZM2.16797 6.33333C2.03536 6.33335 1.9082 6.38603 1.81443 6.47979C1.72066 6.57356 1.66798 6.70073 1.66797 6.83333V12.1667C1.66797 13.1733 2.49471 14 3.5013 14H8.5013C8.63391 14 8.76108 13.9473 8.85484 13.8535C8.94861 13.7598 9.00129 13.6326 9.0013 13.5V6.83333C9.00129 6.70073 8.94861 6.57356 8.85484 6.47979C8.76108 6.38603 8.63391 6.33335 8.5013 6.33333H2.16797ZM10.5013 6.33333C10.3687 6.33335 10.2415 6.38603 10.1478 6.47979C10.054 6.57356 10.0013 6.70073 10.0013 6.83333V9.16667C10.0013 9.29927 10.054 9.42644 10.1478 9.52021C10.2415 9.61397 10.3687 9.66665 10.5013 9.66667H13.8346C13.9672 9.66665 14.0944 9.61397 14.1882 9.52021C14.2819 9.42644 14.3346 9.29927 14.3346 9.16667V6.83333C14.3346 6.70073 14.2819 6.57356 14.1882 6.47979C14.0944 6.38603 13.9672 6.33335 13.8346 6.33333H10.5013ZM2.66797 7.33333H8.0013V13H3.5013C3.03523 13 2.66797 12.6327 2.66797 12.1667V7.33333ZM11.0013 7.33333H13.3346V8.66667H11.0013V7.33333ZM10.5013 10.6667C10.3687 10.6667 10.2415 10.7194 10.1478 10.8131C10.054 10.9069 10.0013 11.0341 10.0013 11.1667V13.5C10.0013 13.6326 10.054 13.7598 10.1478 13.8535C10.2415 13.9473 10.3687 14 10.5013 14H12.5013C13.5079 14 14.3346 13.1733 14.3346 12.1667V11.1667C14.3346 11.0341 14.2819 10.9069 14.1882 10.8131C14.0944 10.7194 13.9672 10.6667 13.8346 10.6667H10.5013ZM11.0013 11.6667H13.3346V12.1667C13.3346 12.6327 12.9674 13 12.5013 13H11.0013V11.6667Z"
          fill="#545454"
        />
      </svg>
    </StyledSvg>
  );
};
