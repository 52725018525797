import { GadgetLogoMark } from "@gadgetinc/widgets/src/icons/GadgetLogo";
import { useStyletron } from "baseui";
import React from "react";

export const DocsAreaLogoType = () => {
  const [css, $theme] = useStyletron();

  return (
    <a
      href="/"
      className={css({
        height: "30px",
        ...$theme.typography.DisplaySmall,
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "30px",
        color: $theme.colors.contentPrimary,
        textDecoration: "none",
        // hide when iframed into the editor
        display: typeof window !== "undefined" && window.self !== window.top ? "none" : "flex",
        flexDirection: "row",
        alignItems: "center",
      })}
    >
      <GadgetLogoMark className={css({ height: "24px" })} />
      <span
        className={css({
          marginLeft: $theme.sizing.scale400,
        })}
      >
        Docs
      </span>
    </a>
  );
};
