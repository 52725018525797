import { useCallback } from "react";
import { useLocation, useRouter } from "wouter";

/**
 * React hook for getting a function to navigate to a given url
 * Useful because it's aware of the router base path, and navigates to external urls properly
 */
export const useDocsLocation = (): [string, (requestedPath: string, options?: { replace?: boolean }) => void] => {
  const router = useRouter();
  const [location, setLocation] = useLocation();

  return [
    location,
    useCallback(
      (requestedPath: string, options?: { replace?: boolean }) => {
        if (requestedPath.startsWith("http")) {
          window.location.href = requestedPath;
        } else {
          setLocation(preserveDocsURLProperties(requestedPath.replace(router.base, "")), options);
        }
      },
      [router.base, setLocation]
    ),
  ];
};

let authToken: string | null = null;

const discoverAuthToken = () => {
  if (typeof window !== "undefined") {
    const url = new URL(window.location.href);
    if (url.searchParams.has("token")) {
      authToken = url.searchParams.get("token");
    }
  }
};
discoverAuthToken();

export const isInternalDocsLink = (href: string) => {
  const parts = href.split("#");
  return (parts[0] === "" && parts[1] !== "") || !href.match(/^https?:\/\//);
};

export const preserveDocsURLProperties = (requestedPath: string) => {
  discoverAuthToken();
  return preserveDocsURLToken(requestedPath, authToken);
};

export const preserveDocsURLToken = (requestedPath: string, token: string | null) => {
  if (isInternalDocsLink(requestedPath) && token) {
    const hasHash = requestedPath.includes("#");
    const hashPart = hasHash ? requestedPath.substring(requestedPath.indexOf("#")) : "";
    const pathWithoutHash = hasHash ? requestedPath.substring(0, requestedPath.indexOf("#")) : requestedPath;

    const hasQuery = pathWithoutHash.includes("?");
    const queryPart = hasQuery ? pathWithoutHash.substring(pathWithoutHash.indexOf("?")) : "";
    const basePath = hasQuery ? pathWithoutHash.substring(0, pathWithoutHash.indexOf("?")) : pathWithoutHash;

    // Create a temporary URL to use searchParams
    const tempUrl = new URL("http://example.com" + (hasQuery ? queryPart : "?"));
    tempUrl.searchParams.set("token", token);

    // Reconstruct the path
    requestedPath = basePath + tempUrl.search + hashPart;
  }

  return requestedPath;
};
