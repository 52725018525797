import { BetaPill } from "@gadgetinc/widgets";
import { AccessControlLockIcon } from "@gadgetinc/widgets/src/icons/AccessControlLockIcon";
import { ActionsDocIcon } from "@gadgetinc/widgets/src/icons/ActionsDocIcon";
import { DataDocIcon } from "@gadgetinc/widgets/src/icons/DataDocIcon";
import { EnvironmentsDocIcon } from "@gadgetinc/widgets/src/icons/EnvironmentsDocIcon";
import { FrontendsDocIcon } from "@gadgetinc/widgets/src/icons/FrontendsDocIcon";
import { GettingStartedIcon } from "@gadgetinc/widgets/src/icons/GettingStartedIcon";
import { ModelsDocIcon } from "@gadgetinc/widgets/src/icons/ModelsDocIcon";
import { PluginsDocIcon } from "@gadgetinc/widgets/src/icons/PluginsDocIcon";
import { RoutesDocIcon } from "@gadgetinc/widgets/src/icons/RoutesDocIcon";
import { SourceControlDocsIcon } from "@gadgetinc/widgets/src/icons/SourceControlDocsIcon";
import { TemplatesIcon } from "@gadgetinc/widgets/src/icons/TemplatesIcon";
import { ToolsDocIcon } from "@gadgetinc/widgets/src/icons/ToolsDocIcon";
import { styled } from "baseui";
import { compact } from "lodash";
import React from "react";
import type { LegacyEnvironmentName } from "state-trees/src/Environment";
import type { GadgetFlags } from "state-trees/src/GadgetFlags";
import { getNamespacedApiIdentifier } from "state-trees/src/utils";
import { getApiReferencesDocsNavItems } from "web/src/components/edit/docs-viewer/ApiReferencesDocsNavItems";
import { Divider } from "../Divider";
import { DocsVersions, type FullMetaBlob } from "../FullMetaBlob";
import type { GadgetItemProps, NavItem, PageLevelHeading } from "./navigation-types";
import { GadgetOnlyHeader } from "./navigation-types";

const Code = styled("span", ({ $theme }) => ({
  fontFamily: $theme.typography.MonoHeadingMedium.fontFamily,
}));

const MultiEnvGuidesHeadings = (flags: GadgetFlags, currentApp: FullMetaBlob): PageLevelHeading[] => [
  {
    nodeName: "H1",
    title: "Getting started",
    icon: <GettingStartedIcon />,
    itemId: `/guides/getting-started`,
    subNav: [
      {
        nodeName: "H2",
        title: "Quickstarts",
        itemId: `/guides/getting-started/quickstarts`,
        subNav: [
          {
            nodeName: "H2",
            title: "Web quickstart",
            itemId: `/guides/getting-started/quickstarts/web-quickstart`,
          },
          {
            nodeName: "H2",
            title: "Shopify quickstart",
            itemId: `/guides/plugins/shopify/quickstarts/shopify-quickstart`,
          },
          {
            nodeName: "H2",
            title: "BigCommerce quickstart",
            itemId: `/guides/getting-started/quickstarts/bigcommerce-quickstart`,
          },
        ],
      },
      {
        nodeName: "H2",
        title: "What is Gadget?",
        itemId: `/guides/getting-started/what-is-gadget`,
      },
      {
        nodeName: "H2",
        title: "How to build a Gadget app",
        itemId: `/guides/getting-started/how-to-build-a-gadget-app`,
      },
      {
        nodeName: "H2",
        title: "How is Gadget different from X",
        itemId: `/guides/getting-started/how-is-gadget-different-from-x`,
      },
      {
        title: "Tutorials",
        nodeName: "H1",
        itemId: `/guides/tutorials`,
        subNav: [
          {
            nodeName: "H1",
            title: "Web apps",
            itemId: `/guides/tutorials/web-ai`,
            subNav: [
              {
                nodeName: "H1",
                title: "OpenAI screenwriter",
                itemId: `/guides/tutorials/ai-screenwriter`,
              },
            ],
          },
          {
            nodeName: "H1",
            title: "Shopify",
            itemId: `/guides/tutorials/shopify`,
            subNav: [
              {
                nodeName: "H1",
                title: "Automated product tagger",
                itemId: `/guides/tutorials/automated-product-tagger`,
              },
              {
                nodeName: "H1",
                title: "Pre-purchase checkout UI extension",
                itemId: `/guides/tutorials/checkout-ui-extension`,
              },
              {
                nodeName: "H1",
                title: "Forward data to an existing service",
                itemId: `/guides/tutorials/middleware`,
              },
              {
                nodeName: "H1",
                title: "Storefront chatbot using OpenAI",
                itemId: `/guides/tutorials/ai-product-recommender`,
              },
              {
                nodeName: "H1",
                title: "Product recommendation quiz",
                itemId: `/guides/tutorials/product-recommendation-quiz-app`,
              },
            ],
          },
          {
            nodeName: "H2",
            title: "BigCommerce",
            itemId: `/guides/tutorials/bigcommerce`,
            subNav: [
              {
                nodeName: "H3",
                title: "Automatically add search keywords to products",
                itemId: `/guides/tutorials/bigcommerce/product-search-keywords`,
              },
              {
                nodeName: "H3",
                title: "Size charts for Catalyst storefronts",
                itemId: `/guides/tutorials/bigcommerce/size-charts`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Models",
    icon: <ModelsDocIcon />,
    itemId: `/guides/models`,
    subNav: [
      {
        nodeName: "H2",
        title: "Fields",
        itemId: `/guides/models/fields`,
      },
      {
        nodeName: "H2",
        title: "Storing files",
        itemId: `/guides/storing-files`,
      },
      {
        nodeName: "H2",
        title: "Relationships",
        itemId: `/guides/models/relationships`,
      },
      {
        nodeName: "H2",
        title: "Namespaces",
        itemId: `/guides/models/namespaces`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Actions",
    icon: <ActionsDocIcon />,
    itemId: `/guides/actions`,
    subNav: [
      {
        nodeName: "H2",
        title: "Types of actions",
        itemId: `/guides/actions/types-of-actions`,
      },
      {
        nodeName: "H2",
        title: "Action code",
        itemId: `/guides/actions/code`,
      },
      {
        nodeName: "H2",
        title: "Background actions",
        itemId: `/guides/actions/background`,
      },
      {
        nodeName: "H2",
        title: "Triggers",
        itemId: `/guides/actions/triggers`,
      },
      {
        nodeName: "H2",
        title: "Namespaces",
        itemId: `/guides/actions/namespaces`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "HTTP routes",
    icon: <RoutesDocIcon />,
    itemId: `/guides/http-routes`,
    subNav: [
      {
        nodeName: "H2",
        title: "Route structure",
        itemId: `/guides/http-routes/route-structure`,
      },
      {
        nodeName: "H2",
        title: "Route configuration",
        itemId: `/guides/http-routes/route-configuration`,
      },
      {
        nodeName: "H2",
        title: "Common use cases",
        itemId: `/guides/http-routes/common-use-cases`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Data access",
    icon: <DataDocIcon />,
    itemId: `/guides/data-access`,
    subNav: [
      {
        nodeName: "H2",
        title: "API access to data",
        itemId: `/guides/data-access/api`,
      },
      {
        nodeName: "H2",
        title: (
          <>
            Computed fields <BetaPill $style={{ transform: "scale(0.8)" }}>Beta</BetaPill>
          </>
        ),
        itemId: `/guides/data-access/computed-fields`,
      },
      {
        nodeName: "H2",
        title: (
          <>
            Gelly <BetaPill $style={{ transform: "scale(0.8)" }}>Beta</BetaPill>
          </>
        ),
        itemId: `/guides/data-access/gelly`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Frontends",
    icon: <FrontendsDocIcon />,
    itemId: `/guides/frontend`,
    subNav: [
      {
        nodeName: "H2",
        title: "Building frontends",
        itemId: `/guides/frontend/building-frontends`,
      },
      {
        nodeName: "H2",
        title: "Building with Remix",
        itemId: `/guides/frontend/remix-in-gadget`,
      },
      {
        nodeName: "H2",
        title: "Building with React Router",
        itemId: `/guides/frontend/react-router-in-gadget`,
      },
      {
        nodeName: "H2",
        title: (
          <>
            Autocomponents <BetaPill $style={{ transform: "scale(0.8)" }}>Beta</BetaPill>
          </>
        ),
        itemId: `/guides/frontend/autocomponents`,
        subNav: [
          {
            nodeName: "H3",
            title: "Polaris",
            itemId: `/guides/frontend/autocomponents/polaris`,
          },
          {
            nodeName: "H3",
            title: "Shadcn",
            itemId: `/guides/frontend/autocomponents/shadcn`,
          },
        ],
      },
      {
        nodeName: "H2",
        title: "Forms",
        itemId: `/guides/frontend/forms`,
      },
      {
        nodeName: "H2",
        title: "Realtime queries",
        itemId: `/guides/frontend/realtime-queries`,
      },
      {
        nodeName: "H2",
        title: "External frontends",
        itemId: `/guides/frontend/external-frontends`,
      },
      {
        nodeName: "H2",
        title: "Optimize LCP",
        itemId: `/guides/frontend/optimize-lcp`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Templates",
    icon: <TemplatesIcon />,
    itemId: `/guides/templates`,
  },
  {
    nodeName: "H1",
    title: "Access control",
    icon: <AccessControlLockIcon />,
    itemId: `/guides/access-control`,
  },
  {
    nodeName: "H1",
    title: "Plugins",
    icon: <PluginsDocIcon />,
    itemId: `/guides/plugins`,
    subNav: [
      {
        nodeName: "H2",
        title: "Shopify",
        itemId: `/guides/plugins/shopify`,
        subNav: [
          {
            nodeName: "H3",
            title: "Quickstarts",
            itemId: `/guides/plugins/shopify/quickstarts`,
            subNav: [
              {
                nodeName: "H4",
                title: "Connecting via Partner dashboard",
                itemId: `/guides/plugins/shopify/quickstarts/partners-quickstart`,
              },
              {
                nodeName: "H4",
                title: "Connecting via store admin",
                itemId: `/guides/plugins/shopify/quickstarts/admin-quickstart`,
              },
            ],
          },
          {
            nodeName: "H3",
            title: "Building Shopify apps",
            itemId: `/guides/plugins/shopify/building-shopify-apps`,
          },
          {
            nodeName: "H3",
            title: "Working with webhooks",
            itemId: `/guides/plugins/shopify/shopify-webhooks`,
          },
          {
            nodeName: "H3",
            title: "Syncing",
            itemId: `/guides/plugins/shopify/syncing-shopify-data`,
          },
          {
            nodeName: "H3",
            title: "Frontends",
            itemId: `/guides/plugins/shopify/frontends`,
          },
          {
            nodeName: "H3",
            title: "Embed previews",
            itemId: `/guides/plugins/shopify/embed-previews`,
          },
          {
            nodeName: "H3",
            title: "Advanced topics",
            itemId: `/guides/plugins/shopify/advanced-topics`,
            subNav: [
              {
                nodeName: "H4",
                title: "Data security",
                itemId: `/guides/plugins/shopify/advanced-topics/data-security`,
              },
              {
                nodeName: "H4",
                title: "Metafields & metaobjects",
                itemId: `/guides/plugins/shopify/advanced-topics/metafields-metaobjects`,
              },
              {
                nodeName: "H4",
                title: "OAuth",
                itemId: `/guides/plugins/shopify/advanced-topics/oauth`,
              },
              {
                nodeName: "H4",
                title: "Billing",
                itemId: `/guides/plugins/shopify/advanced-topics/billing`,
              },
              {
                nodeName: "H4",
                title: "shopify.app.toml files",
                itemId: `/guides/plugins/shopify/advanced-topics/shopify-app-toml`,
              },
              {
                nodeName: "H4",
                title: "Building extensions",
                itemId: `/guides/plugins/shopify/advanced-topics/extensions`,
              },
              {
                nodeName: "H4",
                title: "Customer account UI extensions & authentication",
                itemId: `/guides/plugins/shopify/advanced-topics/customer-account-ui-extensions`,
              },
            ],
          },
          {
            nodeName: "H3",
            title: "API version changelog",
            itemId: `/guides/plugins/shopify/api-version-changelog`,
          },
        ],
      },
      {
        nodeName: "H2",
        title: "BigCommerce",
        itemId: `/guides/plugins/bigcommerce`,
        subNav: [
          {
            nodeName: "H3",
            title: "BigCommerce webhook subscriptions",
            itemId: `/guides/plugins/bigcommerce/webhooks`,
          },
          {
            nodeName: "H3",
            title: "Working with BigCommerce data",
            itemId: `/guides/plugins/bigcommerce/data`,
          },
          {
            nodeName: "H3",
            title: "Building single-click app frontends",
            itemId: `/guides/plugins/bigcommerce/frontends`,
          },
          {
            nodeName: "H3",
            title: "Building App Extensions",
            itemId: `/guides/plugins/bigcommerce/app-extensions`,
          },
          {
            nodeName: "H3",
            title: "Integrate with Catalyst storefronts",
            itemId: `/guides/plugins/bigcommerce/catalyst`,
          },
        ],
      },
      {
        nodeName: "H2",
        title: "OpenAI",
        itemId: `/guides/plugins/openai`,
        subNav: [
          {
            nodeName: "H2",
            title: "Building with OpenAI",
            itemId: `/guides/plugins/openai/building-ai-apps`,
          },
        ],
      },
      {
        nodeName: "H2",
        title: "Sentry",
        itemId: `/guides/plugins/sentry`,
      },
      {
        nodeName: "H2",
        title: "Authentication",
        itemId: `/guides/plugins/authentication`,
        subNav: [
          {
            nodeName: "H2",
            title: "Helpers",
            itemId: `/guides/plugins/authentication/helpers`,
          },
          {
            nodeName: "H2",
            title: "Workflows",
            itemId: `/guides/plugins/authentication/workflows`,
          },
          {
            nodeName: "H2",
            title: "Building with Google OAuth",
            itemId: `/guides/plugins/authentication/google-oauth`,
          },
          {
            nodeName: "H2",
            title: "Building with email/password",
            itemId: `/guides/plugins/authentication/email-pass`,
          },
          {
            nodeName: "H2",
            title: "Removing default authentication methods",
            itemId: `/guides/plugins/authentication/remove-default-auth-methods`,
          },
        ],
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Environments",
    icon: <EnvironmentsDocIcon />,
    itemId: `/guides/environments`,
    subNav: [
      {
        nodeName: "H1",
        title: "Deployment",
        itemId: `/guides/environments/deployment`,
      },
      {
        nodeName: "H1",
        title: "Set up a CI/CD pipeline",
        itemId: `/guides/environments/ci-cd`,
      },
      {
        nodeName: "H1",
        title: "Custom domains",
        itemId: `/guides/environments/custom-domains`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Source control",
    icon: <SourceControlDocsIcon />,
    itemId: `/guides/source-control`,
  },
  {
    nodeName: "H1",
    title: "Development tools",
    icon: <ToolsDocIcon />,
    itemId: `/guides/development-tools`,
    subNav: [
      {
        nodeName: "H1",
        title: "AI assistant",
        itemId: `/guides/development-tools/ai-assistant`,
        subNav: [
          {
            nodeName: "H2",
            title: "Features",
            itemId: `/guides/development-tools/ai-assistant/features`,
          },
          // Commenting out for the time being
          // {
          //   nodeName: "H2",
          //   title: "Prompt guide",
          //   itemId: `/guides/development-tools/ai-assistant/prompt-guide`,
          // },
        ],
      },
      {
        nodeName: "H2",
        title: "Logger",
        itemId: `/guides/development-tools/logger`,
      },
      {
        nodeName: "H2",
        title: "ggt - the Gadget CLI",
        itemId: `/guides/development-tools/cli`,
      },
      {
        nodeName: "H2",
        title: "Environment variables",
        itemId: `/guides/development-tools/environment-variables`,
      },
      {
        nodeName: "H1",
        title: "TypeScript support",
        itemId: `/guides/development-tools/typescript-support`,
      },
      {
        nodeName: "H1",
        title: "Unit testing",
        itemId: `/guides/development-tools/unit-testing`,
      },
      {
        nodeName: "H2",
        title: "Framework linter",
        itemId: `/guides/development-tools/framework-linter`,
      },
      {
        nodeName: "H1",
        title: "Keyboard shortcuts",
        itemId: `/guides/development-tools/keyboard-shortcuts`,
      },
      {
        nodeName: "H1",
        title: "Account and billing",
        itemId: `/guides/account-and-billing`,
      },
    ],
  },
];

const GuidesNav = (location: string, flags: GadgetFlags, currentApp: FullMetaBlob): GadgetItemProps => {
  const subNav = MultiEnvGuidesHeadings(flags, currentApp);

  return {
    title: "Guides",
    itemId: "/guides",
    testIsActive: (location) => {
      const path = location.split("#")[0];
      return path.startsWith("/guides") || DocsVersions.some((version) => version.prefix != "" && path.startsWith(version.prefix));
    },
    subNav,
  };
};

const ReferenceNav = (
  location: string,
  flags: GadgetFlags,
  currentApp: FullMetaBlob,
  environmentName?: LegacyEnvironmentName
): GadgetItemProps => {
  return {
    title: "Reference",
    itemId: "/reference",
    testIsActive: (location) => location.startsWith("/reference"),
    subNav: [
      {
        nodeName: "H1",
        title: "ggt - the Gadget CLI",
        itemId: "/reference/ggt",
      },
      {
        nodeName: "H1",
        title: "Gelly",
        itemId: "/reference/gelly",
      },
      {
        title: <Divider />,
        itemId: `global-actions/divider`,
        disabled: true,
        onlyHeader: GadgetOnlyHeader.Header,
      },
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/react</Code>,
        itemId: "/reference/react",
      },
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/react/auto</Code>,
        itemId: "/reference/react/auto",
      },
      {
        nodeName: "H1",
        title: <Code>gadget-server</Code>,
        itemId: "/reference/gadget-server",
      },
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/react-shopify-app-bridge</Code>,
        itemId: "/reference/shopify-app-bridge",
      },
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/shopify-extensions</Code>,
        itemId: "/reference/shopify-extensions",
      },
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/react-bigcommerce</Code>,
        itemId: "/reference/react-bigcommerce",
      } as GadgetItemProps,
    ],
  };
};

type CurrentApp = Partial<FullMetaBlob> & Pick<FullMetaBlob, "name" | "slug" | "canonicalRenderURL" | "models">;

type TemplateNavItem = NavItem & PageLevelHeading;

export const ApiReferenceNav = (_flags: GadgetFlags, currentApp: CurrentApp): GadgetItemProps & { subNav?: PageLevelHeading[] } => {
  const apiReferenceBase = `/api/${currentApp.slug}/${currentApp.environment?.slug}`;

  const templateArchitectureNav: TemplateNavItem[] = [
    {
      title: `Template architecture`,
      itemId: `${apiReferenceBase}/template-architecture`,
      nodeName: "H1" as const,
    },
  ];

  const templateSetupNav: TemplateNavItem[] = [{ title: `Template setup`, itemId: `${apiReferenceBase}/template-setup`, nodeName: "H1" }];
  const subNav = getApiReferencesDocsNavItems({
    apiReferenceBase,
    templateArchitectureNav: currentApp.hasTemplateConfiguration ? templateArchitectureNav : [],
    templateSetupNav: currentApp.hasTemplateSetup ? templateSetupNav : [],
    modelNamespacedApiIdentifiers: currentApp.models.map((model) => getNamespacedApiIdentifier(model.namespace, model.apiIdentifier)),
    hasGlobalActions: currentApp.hasGlobalActions,
  });

  return {
    title: `API`,
    itemId: apiReferenceBase,
    subNav,
  };
};

/** Returns a list of nested nav items in baseui format */
export const DocsNavItems = (location: string, flags: GadgetFlags, currentApp: FullMetaBlob): GadgetItemProps[] => {
  return compact([GuidesNav(location, flags, currentApp), ApiReferenceNav(flags, currentApp), ReferenceNav(location, flags, currentApp)]);
};
